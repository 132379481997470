
.menubar {
  position: fixed;
  padding-left: 121px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  z-index: 10;
}

.menubar a {
  display: inline;

}

@media only screen and (max-width: 1150px) {
  .menubar {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 1024px) {
  .menubar {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .menubar {
    padding-left: 0.95em;
  }
}

.menubox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
  padding-top: 6.3em;
  padding-bottom: 2em;
  max-width: 130vh;
}

@media only screen and (max-width: 700px) {
  .menubox {
    padding-top: 2.5em;
  }
}

@media only screen and (max-height: 768px) {
  .menubox {
    padding-top: 2.5em;
  }
}

@media only screen and (max-width: 600px) {
  .menubox {
    font-size: 1em;
  }
}

.menu {
  font-family: 'F37 Ginger Light';
  font-size: 1em;
  height: 20px;
  text-align: left;

}


.menuitem {
  cursor: pointer;
  margin: 0 5em 0 0.55em;
  cursor: pointer;
  letter-spacing: 1px;
  color: var(--alt-type-color);
  text-decoration: none;
}

a.menuitem:hover, a.menuitem:visited, a.menuitem:link, a.menuitem:active
{
  text-decoration: none;
  border: none;
}

@media only screen and (max-width: 900px) {
  .menuitem {
    margin: 0 2em 0 0.55em;
  }
}

@media only screen and (max-width: 600px) {
  .menuitem {
    margin: 0 1em 0 0.55em;
  }
}


.menuitem.current {
  text-decoration: none;
  border-bottom: 3px solid var(--link-color);
  color: var(--type-color);
  padding: 0 0.18em 0.333em;
  font-weight: 400;
}

.menuitem:hover {
  color: var(--type-color);
}

.navigator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  z-index: 100;
  flex: 1 0 auto;
}

@media only screen and (max-width: 1000px) {
  .navigator {
    padding: 1em;

    position: fixed;
    right: 7em;
  }
}


@media only screen and (max-width: 600px) {
  .navigator {
    position: fixed;
    right: 2em;
  }
}


@media only screen and (min-width: 481px) and (max-width: 700px) {
  .navigator {
    top: 1em;
  }
}


@media only screen and (min-width: 700px) {
  .navigator {
    top: 5em;
  }
}


@media only screen and (min-width: 481px) and (max-height: 768px) {
  .navigator {
    top: 1em;
  }
}

@media only screen and (max-width: 480px) {
  .navigator {
    padding: 0;
    background-color: var(--background-color);
    bottom: 0em;
    width: 100%;
    height: 5em;
    display: flex;
    justify-content: center;
    left: 0em;
  }
}

/* @media only screen and (min-width: 1400px) {

  .navigator {
    left: 69em;
  }
} */

.next-title {
  padding: 0 1em 0 0;
}

.previtem {
  margin-right: 2em;
  display: flex;
  align-items: center;
}

.previtem img {
  transform: rotate(180deg);
  display: inline-block;
  width: 1.5em;
  margin-right: 1em;
}

.nextitem {
  text-align: center;
  display: flex;
  align-items: center;

}

.nextitem img {
  display: inline-block;
  width: 1.5em;
  margin: 0 0 0 1em;
}

@media only screen and (max-width: 510px) {
    .previtem {
      margin-right: 1em;
    }
}
