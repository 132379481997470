
.more-arrow-red {
  position: absolute;
  opacity: 1;
  width: 3em;
  -webkit-animation-name: hover-red; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
  animation-name: hover-red;
  animation-iteration-count:infinite;
  animation-duration: 3s;
}

.more-arrow-yellow {
  position: absolute;
  width: 3em;
  opacity: 0;
  -webkit-animation-name: hover-yellow; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
  animation-name: hover-yellow;
  animation-iteration-count:infinite;
  animation-duration: 3s;
}

.more-arrow:hover .more-arrow-red {
  -webkit-animation-name: hover-red; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
  animation-name: hover-red;
  animation-iteration-count:infinite;
  animation-duration: 2s;
 }
/* Safari 4.0 - 8.0 */
@-webkit-keyframes hover-red {
  0% {opacity: 1;}
  50% {opacity: 0;}
  0% {opacity: 1;}
}

/* Standard syntax */
@keyframes hover-red {
  0% {opacity: 1;}
  50% {opacity: 0;}
  0% {opacity: 1;}
}

.more-arrow:hover .more-arrow-yellow {
  -webkit-animation-name: hover-yellow; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
  animation-name: hover-yellow;
  animation-iteration-count:infinite;
  animation-duration: 3s;
 }
/* Safari 4.0 - 8.0 */
@-webkit-keyframes hover-yellow {
  0% {opacity: 0;}
  50% {opacity: 1;}
  0% {opacity: 0;}
}

/* Standard syntax */
@keyframes hover-yellow {
  0% {opacity: 0;}
  50% {opacity: 1;}
  0% {opacity: 0;}
}

.more--info-arrow {
  display: inline;
  padding:0.5em 0 0 0.5em;

}

.more--info-arrow {
  position: relative;
  top: 0.25em;
  right: 0.25em;
  width: 1em;
}
