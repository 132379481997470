.img-box {
  margin: 1.5em 3em 1.5em 0;
  max-width: 128vh;
}

.product-img-holder .img-box {
  margin: 1.5em 1.5em 1.5em 0;
}

.islightboxed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.95;
  z-index: 1000000;
  display: flex;
  align-items: space-between;
  justify-content: center;
}
