

@media only screen and (max-width: 500px) {
  .more-arrow.down {
    position: absolute;
    bottom: 5em;
  }
}


@media only screen and (max-width: 700px) {
  .more-arrow.down {
    position: absolute;
    bottom: 5em;
    right: 5em;
  }
}

@media only screen and (min-width: 700px) {
  .more-arrow.down {
    padding-top: 2em;
  }
}
