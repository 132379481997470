.preload {
  display: none;
}

.page {
  background: var(--background-color);
  padding: 0 121px;
}

@media only screen and (max-width: 1150px) {
  .page {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 600px) {
  .page {
    padding: 0 0 0 1.5em;
  }
}

.abovefold {
  height: 100vh;
  max-width: 135vh;
}

.width-constrainer {
  max-width: 135vh;
}

.hero {
  padding-top: 18.6em;
  display: flex;
  justify-content: space-between;
  max-width: 85em;
}

@media only screen and (max-height: 768px) {
  .hero {
    padding-top: 12em;
  }
}

@media only screen and (max-width: 700px) {
  .hero {
    padding-top: 6em;
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .hero {
    padding-top: 6.5em;
    flex-direction: column;
  }
}

.description {
  padding-top: 5.66em;
  width: 26em;
}


@media only screen and (max-width: 700px) {
  .description {
    line-height:1em;
    padding: 0;
  }
}

@media only screen and (max-width: 768px) and (max-height: 600px) {
  .description {
    padding-top: 1em;
    padding-bottom: 0em;
  }
}

.caption {
  display: flex;
  justify-content: space-between;
  margin-bottom:1.33em;
}

.project {
  padding: 1em 0;
  cursor: pointer;
  margin: 0 auto;
}

@media only screen and (max-width: 500px) {
  .project {
    padding: 0em 1.5em 0 0;
  }
}

.projecttitle {
  font-family: 'F37 Ginger';
  color: var(--accent-color);
  font-size: 0.8em;
  margin-right: 0.75em;
  display: inline-block;
  padding: 0.5em 0 0 0;
}

.projectspacer {
  margin: 0 0.75em;
}

.projecttype {
  display: inline-block;
  padding: 0 1em 0 0;
}

.captioncaption {
  font-style: normal;
}

.projecttext {
  margin-right: 0.75em;
  width: 81%;
  padding: 0 0 1em;
}


.projecttextbox {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.33em;
  min-height: 24em;
  padding: 5em 0em 0;
  width: 70%;
}

.credits {
  font-size: 0.75em;
  font-style: italic;
  text-align: right;
  margin: 0 0 1em;
}

.direction {
  font-style: italic;
}

.product-img {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
}

.direction {
  text-align: right;
}

.item-page-tail {
  padding: 0 16px;
}

.tail {
  padding: 5em 0 2em;
  }

.tail .email {
  margin: 0 0 2em 0.5em;
}

.detail-holder {
  position: fixed;
  top: 0px;
  overflow: scroll;
  transition: left 1s;
  width: 100vw;
}

.detail-overlay {
  display: inline-block;
  width: 100vw;

  background-color: var(--background-color);
  height: 100vh;;
  position:relative;
}

.detail {
  display: block;
}

.detail-show {
  top: 0;
  left: 100%;
  z-index: 2;
}

.detail-show.entered {
  left: 0;
}

.detail-hide {
  left: 0;
}

.detail-hide.entered {
  left: 100%;
}

.callout {
  padding: 20em 0 0 0;
}

@media only screen and (max-width: 700px) {
  .callout {
    display: none;
  }
}

@media only screen and (min-width: 1440px) {
  .callout {
    padding: 9em 0 0 0;
  }
}

.back-up-text {
  margin-right: 1.5em;
  text-align: center;
}

.bottom-spacer {
  padding-bottom: 9em;
}

@media only screen and (min-width: 500px) {
  .bottom-spacer {
    padding-bottom: 0em;
  }
}
