.email {
  padding-top: 1.6666em;
  margin: 0 0 2em 0;
}

.email a:hover, .email a:visited, .email a:link, .email a:active{
  display: inline-block;
  border: 1px solid var(--link-color);
  padding: 0.275em 1.125em;
  font-family: 'F37 Ginger';
  letter-spacing: 1px;
  color: #FFF;
}

.email a:hover{
  -webkit-animation-name: hover-email; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 2.6s; /* Safari 4.0 - 8.0 */
  animation-name: hover-email;
  animation-iteration-count:infinite;
  animation-duration: 2.6s;
}

@media only screen and (max-width: 500px) {
  .email {
    padding-top: 0;
    font-size: 1em;
    display: block;
    padding-right: 2em;
    width: 90vw;
    margin: 0.5em;
  }

  .email a:hover, .email a:visited, .email a:link, .email a:active{
    width: 9em;
    font-size: 1.2em;
    padding: 0.275em;
    display: block;
    text-align: center;
  }

}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes hover-email {
  0% {
    color: #FFFFFF;
    border-color: var(--link-color);
  }
  50% {
    color: var(--link-color);
    border-color: #FFFFFF;
    }
  100% {
      color: #FFFFFF;
      border-color: var(--link-color);
    }
}

/* Standard syntax */
@keyframes hover-email {
  0% {
    color: #FFFFFF;
    border-color: var(--link-color);
  }
  50% {
    color: var(--link-color);
    border-color: #FFFFFF;
    }
  100% {
      color: #FFFFFF;
      border-color: var(--link-color);
  }
}
