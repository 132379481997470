.social-media {
  display: flex;
}

.social-media :first-child img{
  padding-left: 0.25em;
}


.social-media a {
  border-bottom: none;
}

.social-media img {
  width: 2em;
  padding: 1em;
}
