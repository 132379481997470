
:root {
  --background-color: #8b8585;
  --type-color: #FFFFFF;
  --accent-color: #F38484;
  --secondary-color: #8b8585;
  --margin: 35px;
  --link-color: #F38484;
  --alt-type-color: #000000;
}
