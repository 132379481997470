/* The animation code */
@keyframes example {
  from {
    clip-path: inset(0px 0px calc(600vh - 80vh) 0px);
    top: 0px;
  }
  to {
    clip-path: inset(calc(600vh - 80vh) 0px 0px 0px);
    top: calc(-600vh + 80vh)
  }
}

.product-animation {
  position: relative;
  top: 0px;
  left: 0px;
  height: 80vh;
}

.screen {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  animation: example;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.computer {
  width: 100%;
  position:absolute;
}
