

.quotes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  line-height:1.4em;
}

.enterquote {
  /* font-size: 4em; */
  font-size: 2.4em;
  line-height:1em;
  margin-bottom: -0.5em;
}

.quote {
  width: 14.5em;
}

.quote-credit {
  margin: 1em 0;
}

.quote-position {
  font-size: 0.75em;
  line-height: 1.25em;
}

@media only screen and (max-width: 600dp) and (max-height: 1366dp){
  .quotes {
    flex-direction: column;
  }

  .quote {
    font-size: 1.3em;
    line-height:1.3em;
    width: 100%;
  }

  .enterquote {
    font-size: 2.3em;
    margin: 0.3em 0 -0.2em;
  }
}

/*  Ipad Landscape */
/* @media only screen and (max-width: 1024px) and (min-height: 1366px){ */
@media only screen and (min-width: 1024dp)  and (min-height: 400px){

  .quotes {
    font-size: 18px;
  }
}
