.decoration {
  position: fixed;
  color: white;
  z-index:100000;
  word-spacing: 3em;
}

.decoration.top {
  top: 0;
  left: 50vw;
}

.decoration-left {
  position: fixed;
  color: white;
  z-index:100000;
  top: 50vh;
  left: 0;
}

.left {
  transform-origin: bottom;
  transform: rotate(270deg);
  word-spacing: 3em;
}

.decoration .right {
  top: 50vh;
  right: 2em;
  transform-origin: bottom;
  transform: rotate(90deg);
}
