@import './fonts/font.css';

body {
  font-family: 'F37 Ginger Thin';
  font-size: 12px;
  color: var(--type-color);
}

a {
  color: var(--type-color);
  text-decoration: none;
  border-bottom: 1px solid var(--type-color);
  cursor: pointer;
}

a:hover {
  color: var(--link-color);
}

h1, h2 {
  margin-block-start: 0em;
  margin-block-end: 0.25em;
  font-family: 'F37 Ginger Light';
  letter-spacing: -2px;
  line-height: 1.1em;
  color: var(--alt-type-color);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: 100px;
}

h2 {
  font-size: 100px;
}

@media only screen and (max-width: 800px) {
  h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 500px) {
  h1, h2 {
    margin-block-start: 0.5em;
    font-size: 3.3em;
    letter-spacing: 0px;
    margin-block-end: 0.0625em;
  }
}

h1 em, h2 em {
  color: var(--type-color);
  font-style: normal;
}

h1 strong {
  font-family: 'F37 Ginger';
}

@media only screen and (min-width: 1440px) {
  h1, h2 {
    font-size: 8em;
    letter-spacing: 0px;
  }
}

/* @media only screen and (max-width: 500px) {
  h2 {
    font-size: 2em;
  }
} */
/*
@media only screen and (min-width: 1440px) {
  h2 {
    font-size: 2em;
  }
} */

h3 {
  font-family: 'F37 Ginger Thin';
  font-size: 1.5em;
}

p {
  display: inline-block;
  margin-block-end: 1.5em;
  margin-right: 3em;
  width: 24em;
  line-height:1.5em;
  max-width:300px;
  margin-block-end: 0.5em;
  letter-spacing: 0.42px;

}

p strong {
  font-family: 'F37 Ginger';
}

@media only screen and (max-width: 500px) {
  p {
    max-width:80vw;
  }
}
